import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import InvoiceListItem from "./InvoiceListItem";

import styles from './ListInvoices.module.css';

class ListInvoices extends React.Component {
    render () {
        return (  
            <React.Fragment>
                <div className={styles.filters}>
                    <Form className="row justify-content-evenly">
                        {/* TODO: add functionality to this */}
                        {/* TODO: add an option to clear the filters */}
                        <Col>
                            <Form.Group>
                                <Form.Label>Filter by client:</Form.Label>
                                <Form.Control as="select" className="form-select" defaultValue={"default"}>
                                    <option disabled value="default" key={"default1"}> -- select -- </option>
                                    {this.props.clients.map(client => (
                                        <option key={client.id.toString()}>{client.name}</option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Filter by job:</Form.Label>
                                <Form.Control as="select" className="form-select" defaultValue={"default"}>
                                    <option disabled value="default" key={"default2"}> -- select -- </option>
                                    {this.props.jobs.map(job => (
                                        <option key={job.number}>{job.name}</option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Search</Form.Label>
                                <Form.Control />
                            </Form.Group>
                        </Col>
                    </Form>
                </div>
                <Container fluid className='mx-0 px-0'>
                    <Row className='d-none d-xxl-flex'>
                        <Col xl={{span: 1}}>
                            <p>Number</p>
                        </Col>
                        <Col xxl={{span: 4}}>
                            <Row>
                                <Col>
                                    <p>Job</p>
                                </Col>
                                <Col>
                                    <p>Client</p>
                                </Col>
                            </Row>
                        </Col>
                        <Col xxl={{span: 1}}>
                            <p className='text-center'>Amount</p>
                        </Col>
                        <Col xxl={{span: 1}}>
                            <p className='text-center'>Due Date</p>
                        </Col>
                        <Col xxl={1}>
                            <p className='text-center'>Issued</p>
                        </Col>
                        
                        <Col xxl={1}>
                            <p className='text-center'>Paid</p>
                        </Col>
                        <Col xxl={1}>
                            <p className='text-center'>Withdrawn</p>
                        </Col>
                        <Col xs={1}></Col>
                    </Row>

                    {this.props.invoices.map(invoice => (
                        <InvoiceListItem 
                            invoice={invoice}
                            updateStatus={this.props.updateStatus}
                            key={invoice.number}
                            setDuplicate={this.props.setDuplicate}
                        />
                    ))}

                </Container>
            </React.Fragment>
        )
    }
}

export default ListInvoices;