import React, { useState } from "react";
import * as Yup from 'yup';
import { Formik, Field } from "formik";
import { Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import styles from './Login.module.css';
import { useNavigate } from "react-router-dom";

const Login = props => {
    const [ showPassword, setShowPassword ] = useState(false)
    const [ error, setError ] = useState(false)
    let navigate = useNavigate()
    const initialFormValues = {
        username: "",
        password: ""
    }

    const validationSchema = (
        Yup.object().shape({
            username: Yup.string().required(),
            password: Yup.string().required()
        })
    )

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);    
    }

    return (
        <div className="d-flex justify-content-center align-items-center vh-100" >
            <div>
                <h1>Please login to continue:</h1>
                <Formik
                    initialValues={initialFormValues}
                    validationSchema={validationSchema}
                    onSubmit={ async (values, ) => {
                        const requestOptions = {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json'},
                            body: JSON.stringify( values )
                        }
                        
                        await fetch('/api/auth/login/', requestOptions)
                                .then(res => {
                                    if (res.status == 401) {
                                        setError(true)
                                    } else if ( res.ok ) {
                                        navigate(0)
                                    }
                                })
                    }}
                    
                >
                    { (form) => 
                        <Form noValidate onSubmit={form.handleSubmit} >
                            <Field name="username">
                                {({ 
                                    field,
                                    meta,
                                }) => (
                                    <Form.Group>
                                        <Form.Label>Username:</Form.Label>
                                        <Form.Control 
                                            placeholder="Username"
                                            {...field}
                                            isInvalid={meta.touched && meta.error}
                                        />
                                        <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
                                    </Form.Group>
                                )}
                            </Field>
                            <Field name="password">
                                {({
                                    field,
                                    meta,
                                }) => (
                                    <Form.Group>
                                        <Form.Label>Password:</Form.Label>
                                        <Form.Control
                                            type={ showPassword ? "text" : "password" }
                                            placeholder="password"
                                            { ...field }
                                            isInvalid={ meta.touched && meta.error }
                                        />
                                        <span className={styles.eye} onClick={togglePasswordVisibility}>
                                            <FontAwesomeIcon icon={ showPassword ? faEye : faEyeSlash } />
                                        </span>
                                        <Form.Control.Feedback type='invalid'>{meta.error}</Form.Control.Feedback>

                                    </Form.Group>
                                )}
                            </Field>
                            {/* TODO: add a keep me logged in option which swaps between session storage and local
                                storage for the cookie */}
                            <Button id="submit" type="submit" className="mt-3">Submit</Button>
                        </Form>
                    
                    }
                </Formik>
                { error && <p className="fw-bold fs-4 text-center my-3 text-danger">Username or password was incorrect</p>}
            </div>
        </div>
    )
}

export default Login;