import React from 'react';
import { Field, Formik } from "formik";
import * as Yup from 'yup';
import { Col, Form, Row, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { useFetchWrapper } from './Hooks/Fetch';

const SettingsPage = props => {
    const fetchWrapper = useFetchWrapper();
    return (
        <>
            <Formik
                initialValues = {{
                    initial_num: props.invoiceNumber,
                }}
                validationSchema = {(
                    Yup.object().shape({
                        initial_num: Yup.number()
                                        .typeError("Must be a number")
                                        .min(1)
                                        .moreThan(props.invoiceNumber, "Must be greater than last invoice issued.")
                    })
                )}
                onSubmit={ async (values) => {
                    await fetchWrapper.post('/api/settings/update/', values)
                          .then(data => props.setNextInvoiceNo(data['inital_num']))
                          .catch(error => { if (error == "Denied") {props.cookies.remove('user') }})
                }}
            >
                { (form) =>
                    <Form noValidate onSubmit={form.handleSubmit}>
                        <Row>
                            <Col>
                                <Field name="initial_num">
                                    { ({field, form: { touched, errors }, meta }) =>
                                        <>
                                        <Form.Group as={Row} control_id="initial_num">
                                            <Form.Label column >Initial Invoice Number</Form.Label>
                                            <Col>
                                                <Form.Control 
                                                    {...field}
                                                    placeholder="0"
                                                    isInvalid={ meta.touched && meta.error }
                                                />
                                                <Form.Control.Feedback type='invalid'>{ meta.error }</Form.Control.Feedback>
                                            </Col>
                                        </Form.Group>
                                        </>
                                    }
                                </Field>
                            </Col>
                            <Col>
                                <Button variant="success" type="submit" >
                                    <FontAwesomeIcon icon={faCheck} />
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                }
            </Formik>
        </>
    )
}

export default SettingsPage;