import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Row, CloseButton, Col } from 'react-bootstrap';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useFetchWrapper } from './Hooks/Fetch';

import styles from "./ListInvoices.module.css";

const ClientModal = props => {
    const initialClientDetails = {name: "", contact: "", address: "", contact_email: "", contact_number: "", payment_terms: 28}
    const [ details, setDetails ] = useState(initialClientDetails)
    const fetchWrapper = useFetchWrapper()

    useEffect(() => {
        setDetails({...initialClientDetails, ...props.clientDetails})
    }, [props.clientDetails])

    const initialFormValues = {
        formType: props.modalAction,
        initialName: details.name,
        clientName: details.name,
        contactName: details.contact,
        address: details.address,
        email: details.contact_email,
        phone: details.contact_number,
        paymentTerms: details.payment_terms,
    }

    const validationSchema = Yup.object().shape({
        clientName: Yup.string()
                       .required('Client Name cannot be blank')
                       .when('formType', {
                           is: "new",
                           then: Yup.string()
                                    .test('clientName', 'This client already exists',
                                        async (value) => (
                                            // Yup expects false if test is not passed and true if it is.
                                            // The API returns true if the job name already exists and false if it doesn't.
                                            // In order to reconcile these we return the opposite of the API result.
                                            
                                            !(await fetchWrapper.post('api/clients/check-duplicate/', { 'clientName': value })
                                                .then(data => data['client exists']))
                                        ))
                       }),
        email: Yup.string().email('Invalid email address'),
        paymentTerms: Yup.number()
                         .required('Payment terms cannot be blank')
                         .min('1', 'Payment terms cannot be less than one (1)')
                         .integer('Payment terms must be whole numbers.')
    })

    return (
        <>
            <Modal
                size='lg'
                centered
                show={props.showClientModal}
                onHide={() => props.handleModalClose('closeClient')}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {{
                            'edit': "Edit Client",
                            'details': details.name,
                            'new': "Set up new client"
                        }[props.modalAction]}
                    </Modal.Title>
                    {/* { props.modalAction !== 'details' && <CloseButton onClick={() => props.handleModalClose('closeClient')}/> } */}
                </Modal.Header>

                {/* VIEW */}
                { props.modalAction === 'details' &&
                    <>
                        <Modal.Body>
                            <Row>
                                <h6>Contact</h6>
                                { details.contact ? <p>{details.contact}</p> : <p className='text-muted'>Not listed</p>}
                            </Row>
                            <Row>
                                <h6>Address</h6>
                                { details.address ? <p>{details.address}</p> : <p className='text-muted'>Not listed</p> }
                            </Row>
                            <Row>
                                <h6>Email</h6>
                                { details.contact_email ? <p>{details.contact_email}</p> : <p className='text-muted'>Not listed</p> }
                            </Row>
                            <Row>
                                <h6>Phone</h6>
                                { details.contact_number ? <p><a href={`tel:${details.contact_number}`}>{details.contact_number}</a></p> : <p className='text-muted'>Not listed</p> }
                            </Row>
                            <Row>
                                <h6>Payment Terms</h6>
                                <p>{details.payment_terms}</p>
                            </Row>
                            <div className='text-end'>
                                <Button id="edit" onClick={() => props.handleModalChange('editClient')}>
                                    <FontAwesomeIcon icon={faPencilAlt} /> Edit
                                </Button>
                            </div>

                            <Row className='border-top mt-3'>
                                <Col>
                                    <h5 className='pt-3'>Jobs</h5>

                                    {props.associatedJobs.map(job => (
                                        <Row key={job.number} className={`py-1 ${styles.hover} rounded`}>
                                            <Col xxl={2}>{job.number}</Col>
                                            <Col>{job.job_name}</Col>
                                            <Col xxl={1}>
                                                <Button onClick={() => props.handleModalChange('editJob', job.number)}>
                                                    <FontAwesomeIcon icon={faPencilAlt} />
                                                </Button>
                                            </Col>
                                        </Row>
                                    ))}

                                    <Button onClick={() => props.handleModalChange('newJob')} className="mt-3">
                                        New job
                                    </Button>
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button id='delete' variant='danger' onClick={props.deleteClient}>
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </Button>
                        </Modal.Footer>
                    </>
                }

                {/* EDIT / NEW */}
                { (props.modalAction === 'edit' || props.modalAction === 'new') && 
                    <Formik
                        enableReinitialize={true}
                        initialValues={initialFormValues}
                        validationSchema={validationSchema}
                        onSubmit={ async (values) => {
                            if (values.formType === 'new') {
                                await fetchWrapper.post('/api/clients/add/', values)
                                    .then(data => {
                                        props.handleModalChange("firstJob", data)
                                    })
                            } else if (values.formType === "edit") {
                                await fetchWrapper.post('api/clients/edit/', values)
                                    .then(data => {
                                        props.handleModalClose("saveClient", {...data, "initialName": values.initialName})
                                    })
                            }
                        }}
                    >
                        { (form) => 
                            <>
                                <Modal.Body>
                                    <Form noValidate onSubmit={form.handleSubmit}>
                                        <Field name="clientName">
                                            {({field, meta}) => (
                                                <Form.Group controlId='clientName' className='mb-3'>
                                                    <Form.Label>Client Name</Form.Label>
                                                    <Form.Control 
                                                        placeholder='Acme Ltd'
                                                        value={field.value}
                                                        {...field}
                                                        isInvalid={meta.touched && meta.error}
                                                    />
                                                    <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
                                                </Form.Group>
                                            )}
                                        </Field>

                                        <Field name="contactName">
                                            {({field, meta}) => (
                                                <Form.Group controlId='contactName' className='mb-3'>
                                                    <Form.Label>Contact Name</Form.Label>
                                                    <Form.Control 
                                                        placeholder='Jo Bloggs'
                                                        value={field.value}
                                                        {...field}
                                                        isInvalid={meta.touched && meta.error}
                                                    />
                                                    <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
                                                </Form.Group>
                                            )}
                                        </Field>

                                        <Field name="address">
                                            {({field, meta}) => (
                                                <Form.Group controlId='address' className='mb-3'>
                                                    <Form.Label>Address</Form.Label>
                                                    <Form.Control
                                                        as='textarea'
                                                        style={{ height: '108px'}}
                                                        placeholder="123 Any Street,&#10;Metropolis,&#10;W1"
                                                        value={field.value}
                                                        {...field}
                                                        isInvalid={meta.touched && meta.error}
                                                    />
                                                    <Form.Control.Feedback type='invalid'>{meta.error}</Form.Control.Feedback>
                                                </Form.Group>
                                            )}
                                        </Field>

                                        <Field name='email'>
                                            {({field, meta}) => (
                                                <Form.Group controlId='email' className='mb-3'>
                                                    <Form.Label>Email</Form.Label>
                                                    <Form.Control
                                                        type='email'
                                                        placeholder='jo.bloggs@acmeltd.com'
                                                        value={field.value}
                                                        {...field}
                                                        isInvalid={meta.touched && meta.error}
                                                    />
                                                    <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
                                                </Form.Group>
                                            )}
                                        </Field>

                                        <Field name='phone'>
                                            {({field, meta}) => (
                                                <Form.Group controlId='phone' className='mb-3'>
                                                    <Form.Label>Phone Number</Form.Label>
                                                    <Form.Control 
                                                        type="tel"
                                                        placeholder='00000 000 000'
                                                        value={field.value}
                                                        {...field}
                                                        isInvalid={meta.touched && meta.error}
                                                    />
                                                    <Form.Control.Feedback type='invalid'>{meta.error}</Form.Control.Feedback>
                                                </Form.Group>
                                            )}
                                        </Field>

                                        <Field name="paymentTerms">
                                            {({field,meta}) => (
                                                <Form.Group controlId='paymentTerms' className='mb-3'>
                                                    <Form.Label>Payment Terms</Form.Label>
                                                    <Form.Control 
                                                        type="number"
                                                        min="1"
                                                        value={field.value}
                                                        {...field}
                                                        isInvalid={meta.touched && meta.error}
                                                    />
                                                    <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
                                                </Form.Group>
                                            )}
                                        </Field>

                                        <Button variant='primary' type="submit" className='float-end'>Save</Button>
                                    </Form>
                                </Modal.Body>
                            </>
                        }
                    </Formik>
                }
            </Modal>
        </>
    )
}

export default ClientModal;