import React from 'react';
import { useLocation } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import styles from './PageHeader.module.css';

const PageHeader = props => {
    let location = useLocation().pathname
    location = location.slice(0, location.lastIndexOf('/')).length > 0 ? location.slice(0, location.lastIndexOf('/')) : location 


    const pickHeader = () => {
        switch(location) {
            case '/': 
                return (
                    <React.Fragment>
                        <h1>Invoices</h1>
                        <Link className="btn btn-primary" to="/new-invoice">New Invoice</Link>
                    </React.Fragment>
                );

            case '/new-invoice': 
                return (
                    <h1>New Invoice</h1>
                );

            case '/clients': 
                return (
                    <React.Fragment>
                        <h1>Clients</h1>
                        <Button onClick={ () => props.handleModalShow("new")}>New Client</Button>
                    </React.Fragment>
                );

            case '/settings': 
                return (
                    <h1>Settings</h1>
                );

            case '/edit': 
                return (
                    <h1>Edit</h1>
                )

            default: 
                return (
                    <h1>404</h1>
                )
        }
    }

    return (
        <header className={`navbar ${styles.pageHeader}`}>
            { pickHeader() }
        </header>
    )
}

export default PageHeader