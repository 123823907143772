import React from "react";
import { Blocker, History, Transition } from 'history';
import { UNSAFE_NavigationContext, useInRouterContext } from "react-router-dom";

function invariant(cond: any, message: string): asserts cond {
    if (!cond) throw new Error(message);
}

function useBlocker(blocker: Blocker, when = true): void {
    // based on: https://github.com/remix-run/react-router/commit/256cad70d3fd4500b1abcfea66f3ee622fb90874#diff-b60f1a2d4276b2a605c05e19816634111de2e8a4186fe9dd7de8e344b65ed4d3L344-L381
    // and: https://github.com/Bilal-Bangash/detecting-route-change-react-route-dom-v6/blob/master/src/hooks/useBlocker.ts

    invariant(
        useInRouterContext(),
        `useBlocker() may only be used in the context of a <Router> component`
    )

    let navigator = React.useContext(UNSAFE_NavigationContext).navigator as History;

    React.useEffect(() => {
        if (!when) return;

        let unblock = navigator.block((tx: Transition) => {
            let autoUnblockingTx = {
                ...tx,
                retry() {
                    unblock();
                    tx.retry();
                }
            };

            blocker(autoUnblockingTx);
        });

        return unblock;
    }, [navigator, blocker, when])
}

export interface PromptProps{
    message: string;
    when?: boolean;
}

export function Prompt({message, when}: PromptProps) {
    usePrompt(message, when);
    return null;
}

export function usePrompt(message: string, when = true) {
    let blocker = React.useCallback( tx => {
            if (window.confirm(message)) tx.retry();
        }, [message])
    
    useBlocker(blocker, when)
}