import React from "react";
import { Col, Row } from "react-bootstrap";

function ViewInvoiceItem(props) {
    const { description, total } = props.item

    return (
        <Row>
            <Col>{description} { props.item.vat === false && "(no vat)" }</Col>
            <Col xs={3}>
                <Row>
                    <Col className="d-flex justify-content-between">
                        <span>£</span>
                        <span>{ parseFloat(total).toFixed(2) }</span>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default ViewInvoiceItem;