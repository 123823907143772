import React from 'react'
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { withCookies } from 'react-cookie';

import styles from './MainNavigation.module.css'

const MainNavigation = props => {
    let location = useLocation();

    const logOut = () => {
        props.cookies.remove('user');
    }

    return (
        // TODO: make responsive
        <nav className={`navbar fixed-top navbar-dark ${styles.customMenu} col col-1 align-items-start`}>
            <ul className="navbar-nav">
                <li className="nav-item">
                    <Link to="/" className={ `nav-link ${location === "/" ? "active" : ""}` }>Invoices</Link>
                </li>
                <li className="nav-item">
                    <Link to="/clients" className={ `nav-link ${location === "/clients" ? "active" : ""}` }>Clients</Link>
                </li>
                <li className="nav-item">
                    <Link to="/settings" className={ `nav-link ${location === "/settings" ? "active" : "" }` }>Settings</Link>
                </li>
                <li className='nav-item'>
                    <a className="nav-link" role="button" onClick={logOut}>Log Out</a>
                </li>
            </ul>
        </nav>
    )
}

export default withCookies(MainNavigation);