import React from "react";
import InvoiceContainer from '../InvoiceContainer';
import Login from '../Auth/Login';
import { BrowserRouter } from "react-router-dom";
import { withCookies } from "react-cookie";

const App = props => {

    return (
        <BrowserRouter>
            { !props.cookies.get('user') && <Login /> }
            { props.cookies.get('user') && <InvoiceContainer /> }
        </BrowserRouter>
    )
}

export default withCookies(App);