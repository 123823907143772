// Based on: https://jasonwatmore.com/post/2021/09/27/react-fetch-logout-on-401-unauthorized-or-403-forbidden-http-response

import React from 'react';

function useFetchWrapper() {
    return {
        get: _request('GET'),
        post: _request('POST'),
        put: _request('PUT'),
        delete: _request('DELETE')
    }

    function _request(method: string) {
        return (url: string, request_body?: Record<string,string>) => {
            const requestOptions: Record<string, any> = {
                method: method,
                headers: { 'Content-Type': 'application/json' },
            }

            if ( typeof request_body != 'undefined' ) {
                requestOptions.body = JSON.stringify(request_body)
            }

            return fetch(url, requestOptions).then(_handleResponse)
        }
    }

    async function _handleResponse(response: any) {
        if (response.status === 401) {
            throw new Error("Denied");
            // TODO: delete the damn cookie over here
        } else {
            let res
            try {
                res = await response.json()
            } catch(err) {
                res = response.statusText
            }
            return res
        }
    }
}

export { useFetchWrapper };