import { Formik, useFormikContext, useField, FieldArray, Field } from "formik";
import React, { createContext, useContext, useEffect, useReducer, useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faPlus, faExclamationTriangle, faCheck } from "@fortawesome/free-solid-svg-icons";
import ClientModal from "./ClientModal";
import JobModal from "./JobModal";
import * as Yup from 'yup';
import ViewInvoiceScheduleItem from "./ViewInvoiceScheduleItem";
import { useNavigate, useParams, } from "react-router-dom";
import { Prompt } from "./Prompt";
import { Cookies, withCookies } from "react-cookie";
import { useFetchWrapper } from "./Hooks/Fetch";

const ClientDropdown = props => {
    const {
        values: { client_id },
        setFieldValue,
    } = useFormikContext()
    const [field, meta] = useField(props)
    const { setShowClientModal } = useContext(InvoiceContext)

    useEffect(() => {
        if ( client_id === 'newClient') {
            setShowClientModal(true)
        } else if ( client_id !== 'default') {
            setFieldValue('client_id', client_id)
        } else {
            setFieldValue('job', 'default')
        }
    }, [client_id, setFieldValue])

    return (
        <>
            <Form.Group controlId={props.name} className="col">
                <Form.Label>Client:</Form.Label>
                <Form.Select name={props.name} {...field} isInvalid={ meta.touched && meta.error }>
                    <option disabled key='default' value='default' > -- Select Client -- </option>
                    { props.clients.map( client => 
                        <option key={client.id.toString()} value={client.name}>{client.name}</option>
                    )}
                    <option key="newClient" value="newClient">Add new client</option>
                </Form.Select>
                <Form.Control.Feedback type='invalid'>{ meta.error }</Form.Control.Feedback>
            </Form.Group>
        </>
    )
}

const JobDropdown = props => {
    const [availableJobs, setAvailableJobs] = useState([])
    const {
        values: { client_id, job_id },
    } = useFormikContext();
    const [field, meta] = useField(props)
    const { setJobDetails, setShowJobModal } = useContext(InvoiceContext)

    useEffect(() => {
        if (job_id === 'newJob') {
            setJobDetails({ client_id: client_id })
            setShowJobModal(true)
        } else if ( job_id !== 'default' ) {
            setJobDetails( props.jobs.filter( job => job.number === job_id )[0] )
        }
    }, [job_id])

    useEffect(() => {
        if ( client_id !== 'default' && client_id !== 'newClient' ) {
            let jobsList = [<option disabled key="default" value="default">-- Select job --</option>]

            jobsList.push(...props.jobs.filter( job => job.client_id === client_id )
                                        .map(job => (
                                            <option key={job.number} value={job.number}>{job.number}: {job.job_name}</option>
                            )))
            jobsList.push(<option key="newJob" value="newJob">Add new job</option>)

            setAvailableJobs(jobsList)

        } else {
            setAvailableJobs([])
        }
    }, [client_id, props.jobs])

    return (
        <>
            <Form.Group controlId="job" className="col">
                <Form.Label>Job:</Form.Label>
                <Form.Select {...field} name={props.name} isInvalid={ meta.touched && meta.error }>
                    { availableJobs }
                </Form.Select>
                <Form.Control.Feedback type='invalid'>{ meta.error }</Form.Control.Feedback>
            </Form.Group>
        </>
    )
}

const DatePicker = ({label, ...props}) => {
    const {
        values: { client_id, issue_date },
        setFieldValue,
    } = useFormikContext();
    const [ field ] = useField(props)

    useEffect(() => {
        let paymentTerms = 28

        if ( client_id !== 'default' && client_id !== 'newClient' ) {
            paymentTerms = (props.clients.filter(c => c.name === client_id))[0].payment_terms
        }

        setFieldValue('issue_date', moment(issue_date).format('YYYY-MM-DD'))
        setFieldValue('due_date', moment(issue_date).add(paymentTerms, 'days').format('YYYY-MM-DD'))
    }, [client_id, issue_date, props.clients, setFieldValue])

    return (
        <>
            <Form.Group controlId={props.name} className="col">
                <Form.Label>{label}</Form.Label>
                <Form.Control {...field} type='date' name={props.name} />
            </Form.Group>
        </>
    )
}

const InvoiceItem = (props) => {
    const {
        values: { time_entries, expense_entries, vat },
        touched,
        setFieldValue,
        handleChange,
    } = useFormikContext();
    const [field, meta] = useField(props)

    useEffect(() => {
        let totalVat = time_entries.map(item => item.total).reduce((vat, total) => {
            return isNaN(parseFloat(total)) ? vat : vat + parseFloat(total)
        }, 0 )

        totalVat += expense_entries.map(item => { 
            if (item.vat) {
                console.log(item)
                return item.total
            }
            return 0
        }).reduce((vat, total) => {
            return isNaN(parseFloat(total)) ? vat : vat + parseFloat(total)
        }, 0)

        setFieldValue('vat', (totalVat*0.2).toFixed(2))
    }, [time_entries, expense_entries, setFieldValue])

    useEffect(() => {
        let total = time_entries.map( item => item.total).reduce( ( t, total ) => {
            return isNaN(parseFloat(total)) ? t : t + parseFloat(total)
        }, 0)
        total += expense_entries.map( item => item.total ).reduce( (t, total) => {
            return isNaN(parseFloat(total)) ? t : t + parseFloat(total)
        }, 0)
        total += parseFloat(vat)
        setFieldValue('total', total.toFixed(2))
    }, [time_entries, expense_entries, vat, setFieldValue])

    const vals = props.name === 'time_entries' ? time_entries : expense_entries

    const updateDependentValues = (e, index) => {
        let re = RegExp(/[^\.]+\.+[^\.]/)
        let multiply = (e.target.name).split('.').pop() === 'amount' ? vals[index].qty : vals[index].amount
        let tot = (e.target.value * multiply).toString()

        setFieldValue(`${e.target.name.match(re)}.total`,
                      tot !== "NaN" ? tot : <FontAwesomeIcon icon={faExclamationTriangle} className="text-danger fs-4" />
        )
    }

    return (
        <>
            <FieldArray name={props.name}>
                {
                    arrayHelpers  => {
                        let entries = null

                        if ( vals && vals.length > 0 ) {
                            entries = vals.map((entry, index) => (
                                <Row key={index} className="mb-3">
                                    <Col xs={1}>
                                        <Form.Control
                                            {...field}
                                            name={`${props.name}.${index}.qty`}
                                            placeholder="0"
                                            value={ vals[index].qty.length > 0 ? entry.qty : "" }
                                            onChange={e => {
                                                handleChange(e)
                                                updateDependentValues(e, index)
                                            }}
                                            isInvalid={ 
                                                meta.touched && touched[props.name][index] &&
                                                meta.error && meta.error[index] && meta.error[index].qty 
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            { meta.error && meta.error[index] && meta.error[index].qty ? meta.error[index].qty : "" }
                                        </Form.Control.Feedback>
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            {...field}
                                            name={`${props.name}.${index}.description`}
                                            placeholder="Description"
                                            value={ entry.description.length > 0 ? entry.description : "" }
                                            onChange={handleChange}
                                            isInvalid={ 
                                                meta.touched && touched[props.name][index] &&
                                                meta.error && meta.error[index] && meta.error[index].description
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            { meta.error && meta.error[index] && meta.error[index].description ? meta.error[index].description : ""}
                                        </Form.Control.Feedback>
                                    </Col>
                                    <Col xs={1}>
                                        <Form.Control
                                            {...field}
                                            name={`${props.name}.${index}.amount`}
                                            placeholder={(0).toFixed(2)}
                                            value={ entry.amount.length > 0 ? entry.amount : "" }
                                            onChange={e => {
                                                handleChange(e)
                                                updateDependentValues(e, index)
                                            }}
                                            isInvalid={
                                                meta.touched && touched[props.name][index] &&
                                                meta.error && meta.error[index] && meta.error[index].amount
                                            }
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            { meta.error && meta.error[index] && meta.error[index].amount ? meta.error[index].amount : ""}
                                        </Form.Control.Feedback>
                                    </Col>
                                    { props.name === "expense_entries" && 
                                        <Col xs={1} className="text-center">
                                            <Form.Check 
                                                {...field}
                                                name={`${props.name}.${index}.vat`}
                                                type={'checkbox'}
                                                checked={ entry.vat }
                                                onChange={handleChange}
                                            />
                                        </Col>
                                    }
                                    <Col xs={1}>
                                        <p className="text-end">
                                            { entry.total.length === 0 && (0).toFixed(2) }
                                            { !isNaN(entry.total) && entry.total.length > 0 && parseFloat(entry.total).toFixed(2) }
                                            { isNaN(entry.total) && entry.total }
                                        </p>
                                    </Col>
                                    <Col xs={1} className="text-end">
                                        <Button
                                            name={`${props.name}.${index}`}
                                            variant="outline-danger"
                                            onClick={() => arrayHelpers.remove(index)}
                                        >
                                            <FontAwesomeIcon icon={faTrashAlt} />
                                        </Button>
                                    </Col>
                                </Row>
                            ))
                        }

                        return (
                            <>
                                { entries }
                                <Row className="justify-content-end">
                                    <Col xs={1} className="text-end">
                                        <Button 
                                            variant="success"
                                            onClick={() => arrayHelpers.push({ qty: "", description: "", amount: "", total: "" })}
                                        >
                                            <FontAwesomeIcon icon={faPlus} />
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                        )
                    }
                }
            </FieldArray>                
        </>
    )
}

const Schedule = props => {
    const [ incSchedule, setIncSchedule ] = useState([])

    const {
        values: { number, job_id, schedule }
    } = useFormikContext()

    useEffect(() => {
        const fetchScheduleItems = async() => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 'job_number': job_id })
            }

            await fetch('/api/invoices/list/', requestOptions)
                        .then(res => {
                            if (res.status === 401) {
                                props.cookies.remove('user')
                                throw new Error("401 Forbidden")
                            }  
                            return res.json()
                        }).then(data => setIncSchedule(data['invoices'].sort((a,b) => a.number > b.number ? 1 : -1)))
        }
        
        if (schedule) {
            fetchScheduleItems()
        } else {
            setIncSchedule([])
        }

    }, [schedule, job_id])

    return (
        <>
            { schedule ? (
                <>
                    <Row>
                        <h3>Schedule</h3>
                    </Row>
                    <Row style={{"background-color": "#d2c3d7"}} className="py-1">
                        <Col lg={1}>Date</Col>
                        <Col>No.</Col>
                        <Col lg={1} className="text-center">Amount</Col>
                        <Col lg={1} className="text-center">Payment</Col>
                    </Row>
                    { incSchedule.map( s => {
                        return s.number <= number  && !s.withdrawn ?
                            <ViewInvoiceScheduleItem
                                key={s.number} 
                                item={s}
                                job_number={ job_id }
                                current_invoice={ s.number === number }
                            />
                        : ""
                    })}
                </>
            ) : "" }
        </>
    )
}

const WorkSchedule = () => {
    const {
        values: { work_schedule }
    } = useFormikContext()

    return (
        <Field name="work_schedule_text">
            { ({field, meta}) => work_schedule ? ( 
                    <Row className="mb-3">
                        <p className="small text-secondary">Bullet points may be copied from Word or created using - or
                         * followed by a space.</p>
                        <Form.Control 
                            {...field}
                            name="work_schedule_text"
                            as="textarea"
                            rows={10}
                            placeholder="Details of work"
                            isInvalid={ meta.touched && meta.error }
                        />
                        <Form.Control.Feedback type='invalid'>
                            { meta.error }
                        </Form.Control.Feedback>
                    </Row>
                ) : "" }
        </Field>
    )
}

const Modals = props => {
    const { setFieldValue } = useFormikContext()
    const {
        showClientModal,
        showJobModal,
        jobDetails,
        setJobDetails,
        setShowClientModal,
        setShowJobModal
    } = useContext(InvoiceContext)

    const handleModalClose = ( e, v ) => {
        switch(e) {
            case 'saveClient':
                props.updateClientsList(v)
                setFieldValue('client_id', v.client_id)
                break
            case 'closeClient':
                setFieldValue('client_id', 'default')
                break
            case 'saveJob':
                props.updateJobsList(v)
                setFieldValue('job_id', v.number)
                break
            default:
                setFieldValue('job_id', 'default')
                break
        }
        setShowClientModal(false)
        setShowJobModal(false)
    }

    const handleModalChange = ( e, v ) => {
        props.updateClientsList(v)
        setFieldValue('client_id', v.name)
        setJobDetails( { 'client_id': v.name } )
        setShowClientModal(false)
        setShowJobModal(true)
    }

    return (
        <>
            <ClientModal
                showClientModal={showClientModal}
                handleModalClose={handleModalClose}
                handleModalChange={handleModalChange}
                modalAction={'new'}
            />
            <JobModal
                showJobModal={showJobModal}
                handleModalClose={handleModalClose}
                modalAction={'new'}
                jobDetails={jobDetails}
            />
        </>
    )
}

const initialState = {
    showClientModal: false,
    showJobModal: false,
    jobDetails: {},
    initialFormValues: {
        number: -1,
        client_id: "default",
        job_id: "default",
        inc_client_inv_num: true,
        issue_date: moment().format('YYYY-MM-DD'),
        due_date: moment().add(28, 'days').format('YYYY-MM-DD'),
        time_entries: [
            { qty: "", description: "", amount: "", total: "" },
            { qty: "", description: "", amount: "", total: "" },
            { qty: "", description: "", amount: "", total: "" }
        ],
        expense_entries: [
            { qty: "", description: "", amount: "", vat: false, total: "" },
            { qty: "", description: "", amount: "", vat: false, total: "" },
            { qty: "", description: "", amount: "", vat: false, total: "" }
        ],
        vat: "0",
        total: "0",
        schedule: false,
        work_schedule: false,
        work_schedule_text: "",
    }
}

function reducer(state, action) {
    switch(action.type) {
        case 'load_invoice_data':
            return {
                ...state,
                initialFormValues: action.data
            }
        case 'toggle_client_modal':
            return {
                ...state,
                showClientModal: action.state
            }
        case 'toggle_job_modal':
            return {
                ...state,
                showJobModal: action.state
            }
        case 'set_invoice_number':
            return {
                ...state,
                initialFormValues: { ...state.initialFormValues, number: action.number }
            }
        
        case 'set_job_details':
            return {
                ...state,
                jobDetails: action.job
            }
        
        case 'update_initial_form_values':
            return {
                ...state,
                initialFormValues: { ...state.initialFormValues, ...action.values }
            }
        default:
            return {
                ...state
            }
    }
}

const InvoiceContext = createContext();

const Provider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const value = {
        showClientModal: state.showClientModal,
        showJobModal: state.showJobModal,
        jobDetails: state.jobDetails,
        initialFormValues: state.initialFormValues,
        loadInvoiceData: ( data ) => {
            dispatch({ type: 'load_invoice_data', data: data})
        },
        setShowClientModal: (val) => {
            dispatch({ type: 'toggle_client_modal', state: val })
        },
        setShowJobModal: (val) => {
            dispatch({ type: 'toggle_job_modal', state: val })
        },
        setInvoiceNumber: ( number ) => {
            dispatch({ type: 'set_invoice_number', number: number })
        },
        setJobDetails: ( job ) => {
            dispatch({ type: 'set_job_details', job: job })
        },
        updateInitialFormValues: ( values ) => {
            dispatch({ type: 'update_initial_form_values', values: values })
        }
    }

    return (
        <InvoiceContext.Provider value={value}>
            { children }
        </InvoiceContext.Provider>
    )
}

const NewInvoiceForm = props => {
    const {
        initialFormValues,
        setInvoiceNumber,
        updateInitialFormValues } = useContext(InvoiceContext);
    let navigate = useNavigate()
    let params = useParams()
    const fetchWrapper = useFetchWrapper()

    useEffect(() => {
        const fetchEntries = async (values) => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 'invoice_number': values.number })
            }

            await fetch('/api/invoices/entries/', requestOptions)
                        .then(res => res.json())
                        .then(data =>  {
                            const updated = { ...values }

                            if ( data.time_entries.length < 3 ) {
                                updated.time_entries = [ ...data.time_entries, ...updated.time_entries.slice(data.time_entries.length) ]
                            } else {
                                updated['time_entries'] = [ ...data["time_entries"] ]
                            }

                            if ( data.expense_entries.length < 3 ) {
                                updated.expense_entries = [ ...data.expense_entries, ...updated.expense_entries.slice(data.expense_entries.length) ]
                            } else {
                                updated['expense_entries'] = [ ...data["expense_entries"] ]
                            }

                            if (props.duplicate) {
                                updated.number = props.invoiceNumber
                                updated.issue_date = moment().format('YYYY-MM-DD')
                                let payment_terms = (props.clients.filter(c => c.name === updated.client_id))[0].payment_terms
                                updated.due_date = moment().add(payment_terms, 'days').format('YYYY-MM-DD')
                            }

                            updateInitialFormValues(updated)
                        })
        }
  
        if ( params.num || props.duplicate ) {
            let number = params.num ? params.num : props.duplicate
            let invoice = props.invoices.filter( i => i.number === parseInt(number) )[0]
            fetchEntries({...initialFormValues, ...invoice}).catch(console.error);
        }
        
        return function cleanup() {
            props.setDuplicate(null)
        }
    }, [params, props.invoices, props.duplicate])

    useEffect(() => {
        if ( !params.num ) {
            setInvoiceNumber(props.invoiceNumber)
        }
    }, [params, props.invoiceNumber])

    const validationSchema = (
        Yup.object().shape({
            client_id: Yup.string().notOneOf(['default'], 'Select a client').required(),
            job_id: Yup.string().notOneOf(['default'], 'Select a job').required(),
            time_entries: Yup.array().of(
                Yup.object().shape({
                    qty: Yup.number()
                            .typeError("Must be a number")
                            .moreThan(0, 'Must be greater than 0')
                            .when(['amount'], {
                                is: amount => !isNaN(amount) && amount > 0,
                                then: Yup.number().required("Must have a quantity")
                            }),
                    description: Yup.string().when(['qty', 'amount'], {
                        is: (qty, amount) => qty > 0 || amount > 0,
                        then: Yup.string().required('Each item must have a description'),
                    }),
                    amount: Yup.number()
                            .typeError("Must be a number")
                            .moreThan(0, 'Must be greater than 0')
                            .when(['qty'], {
                                is: qty => !isNaN(qty) && qty > 0,
                                then: Yup.number().required("Must have a rate")
                            })
                }, [ ["qty", "amount"] ])
            ),
            expense_entries: Yup.array().of(
                Yup.object().shape({
                    qty: Yup.number()
                            .typeError("Must be a number")
                            .moreThan(0, 'Must be greater than 0')
                            .when(['amount'], {
                                is: amount => amount > 0,
                                then: Yup.number().required("Must have a quantity")
                            }),
                    description: Yup.string().when(['qty', 'amount'], {
                        is: (qty, amount) => qty > 0 || amount > 0,
                        then: Yup.string().required('Each item must have a description'),
                    }),
                    amount: Yup.number()
                            .typeError("Must be a number")
                            .moreThan(0, 'Must be greater than 0')
                            .when(['qty'], {
                                is: qty => qty > 0,
                                then: Yup.number().required("Must have a value")
                            }),
                }, ['qty', 'amount'])
            ),
            work_schedule: Yup.boolean(),
            work_schedule_text: Yup.string().when("work_schedule", {
                is: true,
                then: Yup.string()
                         .required("Please input details of work or deselect this field using the checkbox above")
            })
        })
    )

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={initialFormValues}
                validationSchema={validationSchema}
                onSubmit={ async(values) => {
                    
                    if (params.num) {
                        await fetchWrapper.post('/api/invoices/edit/', values)
                            .then(data => {
                                props.updateInvoicesList({0: data})
                                navigate(`/invoice/${data.number}`)
                            })
                    } else {
                        await fetchWrapper.post('/api/invoices/add/', values)
                            .then(data => {
                                props.setDuplicate(null)
                                props.updateInvoicesList({0: data})
                                navigate(`/invoice/${data.number}`)
                            })
                    }
                }
            }
            >
                { (form) =>
                    <>
                        <Prompt when={form.dirty && !form.isSubmitting } message="Changes will be lost"/>
                        <Form noValidate onSubmit={form.handleSubmit}>
                            <Row>
                                <Col>
                                    <p>Our Ref: {form.values.job_id !== 'default' ? form.values.job_id : "MNY-00-00"}/{form.values.number}</p>
                                </Col>
                                <Col>
                                    <Field name="inc_client_inv_num">
                                        {({field}) => (
                                            <Form.Check
                                                className="ms-2"
                                                type={'checkbox'}
                                                name="clientInvNum_check"
                                                checked={field.value}
                                                {...field} 
                                                label="Include client invoice number?" 
                                            />
                                        )}
                                    </Field>
                                </Col>
                            </Row>
                            <div className="row">
                                <ClientDropdown 
                                    name="client_id"
                                    clients={props.clients}
                                />
            
                                <JobDropdown 
                                    name="job_id"
                                    jobs={props.jobs}
                                />
                            </div>
                            <div className="row">
                                <DatePicker
                                    label="Issue Date:" 
                                    name='issue_date'
                                    clients={props.clients}
                                />
                                <DatePicker 
                                    label="Due Date:"
                                    name='due_date'
                                    clients={props.clients}
                                />
                            </div>
            
                            <div className="row">
                                <h2>Time entries:</h2>
                            </div>
                            <Row>
                                <Col xs={1}>
                                    <p>Qty</p>
                                </Col>
                                <Col>
                                    <p>Description</p>
                                </Col>
                                <Col xs={1}>
                                    <p className="text-center">Rate</p>
                                </Col>
                                <Col xs={1}>
                                    <p className="text-end">Line Total</p>
                                </Col>
                                <Col xs={1}></Col>
                            </Row>
                            <InvoiceItem name="time_entries" />

                            <div className="row">
                                <h2>Expenses:</h2>
                            </div>
                            <Row>
                                <Col xs={1}>
                                    <p>Qty</p>
                                </Col>
                                <Col>
                                    <p>Description</p>
                                </Col>
                                <Col xs={1}>
                                    <p className="text-center">Rate</p>
                                </Col>
                                <Col xl={1}>
                                    <p className="text-center">Include<br />VAT?</p>
                                </Col>
                                <Col xs={1}>
                                    <p className="text-end">Line Total</p>
                                </Col>
                                <Col xs={1}></Col> 
                            </Row>
                            <InvoiceItem name="expense_entries" />

                            <Row className='justify-content-end mt-3'>
                                <Col xs={1}>
                                    <p className="text-center">VAT</p>
                                </Col>
                                <Col xs={1}>
                                    <Field name="vat">
                                        {({field}) => 
                                            <p className="text-end" {...field} >{parseFloat(field.value).toFixed(2)}</p>
                                        }
                                    </Field>
                                </Col>
                                <Col xs={1}></Col>
                            </Row>

                            <Row className="justify-content-end mt-3">
                                <Col xs={1}>
                                    <p className="fw-bold text-center">INVOICE TOTAL</p>
                                </Col>
                                <Col xs={1}>
                                    <Field name="total">
                                        {({field}) => 
                                            <p className="fw-bold text-end" {...field} >{parseFloat(field.value).toFixed(2)}</p>
                                        }
                                    </Field>
                                </Col>
                                <Col xs={1}></Col>
                            </Row>
                            <Row>
                                <Field name="work_schedule">
                                    {({field}) => (
                                        <Form.Check 
                                            className="ms-2"
                                            type={'checkbox'}
                                            name="work_schedule_check"
                                            checked={field.value}
                                            {...field}
                                            label="Include Schedule?"
                                        />
                                    )}
                                </Field>
                            </Row>
                            <WorkSchedule />
                            <Row>
                                <Field name="schedule">
                                    {({field}) => (
                                        <Form.Check
                                            className="ms-2"
                                            type={'checkbox'}
                                            name="schedule_check"
                                            checked={field.value}
                                            {...field} 
                                            label="Include Invoice Schedule?" 
                                        />
                                    )}
                                </Field>
                            </Row>
                            <Schedule />
                            <Row className="justify-content-center align-items-center mb-3">
                                <Col xs="auto">
                                    <Button 
                                        variant="outline-danger"
                                        onClick={() => {
                                            navigate('/')
                                        }}
                                    >
                                        Discard changes <FontAwesomeIcon icon={faTrashAlt} />
                                    </Button>
                                </Col>
                                <Col xs="auto">
                                    <Button variant="success" size="lg" type='submit' >
                                        {params.num ? "Update" : "Generate" } <FontAwesomeIcon icon={faCheck} />
                                    </Button>
                                </Col>
                            </Row>

                            <Modals 
                                updateClientsList={props.updateClientsList}
                                updateJobsList={props.updateJobsList}
                            />
                        </Form>
                    </>
                }
            </Formik>
        </>
    )
}

const NewInvoice = props => (
    <Provider>
        <NewInvoiceForm 
            invoiceNumber={props.invoiceNumber}
            clients={props.clients}
            jobs={props.jobs}
            invoices={props.invoices}
            duplicate={props.duplicate}
            setDuplicate={props.setDuplicate}
            updateClientsList={props.updateClientsList}
            updateJobsList={props.updateJobsList}
            updateInvoicesList={props.updateInvoicesList}
        />
    </Provider>
)

export default withCookies(NewInvoice);