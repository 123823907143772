import React, { useEffect, useState, useCallback } from 'react';
import { Route, Routes } from 'react-router-dom';
import { withCookies } from 'react-cookie';

import MainNavigation from './Navigation/MainNavigation';
import PageHeader from './Navigation/PageHeader';
import ListInvoices from './ListInvoices';
import ListClients from './ListClients';
import NewInvoice from './NewInvoice';
import SettingsPage from './SettingsPage';
import Invoice from './ViewInvoice';
import { useFetchWrapper } from './Hooks/Fetch';

// TODO: Create app context so that there isn't so much passing around of props

const InvoiceContainer = props => {

    const [ clients, setClients ] = useState([])
    const [ jobs, setJobs ] = useState([])
    const [ invoices, setInvoices ] = useState([])
    const [ invoiceDisplayN, setInvoiceDisplayN ] = useState(10)
    const [ invoiceDisplayStartN, setInvoiceDisplayStartN ] = useState(0)
    const [ nextInvoiceNo, setNextInvoiceNo ] = useState(-1)
    const [ duplicate, setDuplicate ] = useState(null)
    const fetchWrapper = useFetchWrapper();

    const fetchInvoices = useCallback(async () => {
        await fetchWrapper.post('/api/invoices/list/',
                                    { startNumber: invoiceDisplayStartN, quantityToReturn: invoiceDisplayN })
                             .then(data => setInvoices(data['invoices'].sort((a,b) => a.number > b.number ? -1 : 1)))
                             .catch(error => { if (error == "Denied") {props.cookies.remove('user') }})
    }, [invoiceDisplayN, invoiceDisplayStartN])
                
    const fetchClients = useCallback(async () => {
        await fetchWrapper.get('/api/clients/list/')
        .then(data => setClients(data['clients'].sort((a,b) => a.name > b.name ? 1 : -1)))
        .catch(error => { if (error === "Denied") {props.cookies.remove('user') }})
    }, [])
    
    const fetchJobs = useCallback(async () => {
        await fetchWrapper.get('/api/jobs/list/')
        .then(data => setJobs(data['jobs'].sort((a,b) => a.number > b.number ? 1 : -1)))
        .catch(error => { if (error === "Denied") {props.cookies.remove('user') }})
    }, [])

    const fetchStartingInvoiceNumber = async () => {        
        let num = await fetchWrapper.get('/api/settings/initial-invoice-number/').then(data => data['initial_num'])
                                 .catch(error => { if (error === "Denied") {props.cookies.remove('user') }})
        return num;
    }

    useEffect(() => {
        fetchInvoices().catch(console.error);
        fetchClients().catch(console.error);
        fetchJobs().catch(console.error);

        document.title = "MNY Incvoicing System"
    }, [])


    useEffect(() => {
        const highestInvoiceNo = Math.max(...invoices.map(invoice => invoice.number)) + 1

        fetchStartingInvoiceNumber().then(num => {
            let presetInvNo = num

            if (isNaN(presetInvNo) || highestInvoiceNo >= presetInvNo ) {
                setNextInvoiceNo(highestInvoiceNo)
            } else {
                setNextInvoiceNo(presetInvNo)
            }

        })

    }, [invoices, nextInvoiceNo])

    const updateStatus = (type, number, status) => {
        fetchWrapper.post(
                '/api/invoices/change-status/',
                { 'type': type, 'number': number, 'status': status})
            .then(data => {
                setInvoices(invoices => invoices.map(invoice => {
                    if (invoice.number === data.number) {
                        return data
                    }
                    return invoice
                }))
            })
            .catch(error => { console.log(error) })
    }

    const updateClientsList = newClient => {
        if ( newClient.delete === true ) {
            setJobs([
                ...jobs.filter(j => {
                    return j.client_id !== newClient.name
                })
            ])
            setClients([
                ...clients.filter(c => {
                    return c.name !== newClient.name
                })
            ])
        } else if ( clients.filter(client => newClient.initialName === client.name).length > 0) {
            setClients(clients => clients.map(client => {
                if (client.name === newClient.initialName) {
                    return newClient
                }
                return client
            }).sort((a,b) => a.name > b.name ? 1 : -1))
        } else {
            setClients([...clients, newClient].sort((a,b) => a.name > b.name ? 1 : -1))
        }
    }

    const updateJobsList = job => {
        if ( job.deleted === true ) {
            setJobs([
                ...jobs.filter(j => {
                    return j.number !== job.number
                })
            ])
        } else if (jobs.filter(j => j.number === job.initialNumber).length > 0) {
            setJobs(jobs => jobs.map(j => {
                if (j.number === job.initialNumber) {
                    return job
                }
                return j
            }).sort((a,b) => a.number > b.number ? 1 : -1))
        } else {
            setJobs([...jobs, job].sort((a,b) => a.number > b.number ? 1 : -1))
        }
    }

    const updateInvoicesList = changed => {
        Object.values(changed).forEach(invoice => {
            if ( invoice.deleted === true ) {
                console.log(`invoice ${invoice.number} deleted`)
                setInvoices([
                    ...invoices.filter(i => {
                        return i.number !== parseInt(invoice.number)
                    })
                ])
            } else if ( invoice.number < nextInvoiceNo ) {
                setInvoices(invoices => invoices.map(i => {
                    if (i.number === invoice.number) {
                        return invoice
                    }
                    return i
                }))
            } else {
                setInvoices([...invoices, invoice].sort((a,b) => a.number > b.number ? -1 : 1))
            }
        })
    }

    return (
            <div className="container-fluid row">
                <MainNavigation />
                <div className="container col offset-1 body">
                    <Routes>
                        {/* TODO: move this outside the main navigation */}
                        {/* TODO: lock all routes */}

                        <Route path="/" element={
                            <React.Fragment>
                                <PageHeader />
                                <ListInvoices
                                    invoices={invoices}
                                    clients={clients}
                                    jobs={jobs}
                                    updateStatus={updateStatus}
                                    setDuplicate={setDuplicate}
                                />
                            </React.Fragment>
                        }/>
                        
                        <Route path="/clients" element={
                            <React.Fragment>
                                <ListClients 
                                    clients={clients}
                                    jobs={jobs}
                                    updateClientsList={updateClientsList}
                                    updateJobsList={updateJobsList}
                                    updateInvoiceList={updateInvoicesList}
                                />
                            </React.Fragment>
                        }/>
                        
                        <Route path="/new-invoice" element={
                            <React.Fragment>
                                <PageHeader />
                                <NewInvoice 
                                    invoiceNumber={nextInvoiceNo}
                                    clients={clients}
                                    jobs={jobs}
                                    invoices={invoices}
                                    updateClientsList={updateClientsList}
                                    updateJobsList={updateJobsList}
                                    updateInvoicesList={updateInvoicesList}
                                    duplicate={duplicate}
                                    setDuplicate={setDuplicate}
                                />
                            </React.Fragment>
                        }/>

                        <Route path="/edit/:num" element={
                            <>
                                <PageHeader />
                                <NewInvoice
                                    clients={clients}
                                    jobs={jobs}
                                    invoices={invoices}
                                    updateInvoicesList={updateInvoicesList}
                                    updateClientsList={updateClientsList}
                                    updateJobsList={updateJobsList}
                                    setDuplicate={setDuplicate}
                                />
                            </>
                        }/>

                        <Route path="/settings" element={
                            <React.Fragment>
                                <PageHeader />
                                <SettingsPage 
                                    invoiceNumber={nextInvoiceNo}
                                    setNextInvoiceNo={setNextInvoiceNo}
                                />
                            </React.Fragment>
                         } />

                        {/* TODO: handle non-existant invoice numbers */}
                        <Route path="/invoice/:num" element={
                            <>
                                <Invoice 
                                    invoices={invoices}
                                    clients={clients}
                                    updateInvoicesList={updateInvoicesList}
                                    setDuplicate={setDuplicate}
                                />
                            </>
                         } />

                        <Route path="*" element={
                            <PageHeader />
                        }/>
                    </Routes>
                </div>
            </div>
    )
}

export default withCookies(InvoiceContainer);

