import React, { useEffect, useReducer } from 'react';
import { Modal, Button, Form, } from 'react-bootstrap';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useFetchWrapper } from './Hooks/Fetch';

const initialState = {
    details: {
        number: "",
        client: "",
        name: "",
        client_invoice_start_number: "1"
    },
    formType: "new"
}

function init(initialState) {
    return initialState;
}

function reducer(state, action) {
    switch (action.type) {
        case 'setDetails':
            return {
                ...state,
                details: { ...state.details, ...action.details }
            }
        case 'setFormType':
            return {
                ...state,
                formType: action.formType
            }
        case 'reset':
            return init(action.payload)
        default:
            return { ...state }
    }
}

const JobModal = props => {
    const [ state, dispatch ] = useReducer( reducer, initialState, init )
    const fetchWrapper = useFetchWrapper();

    useEffect(() => {
        dispatch( { type: 'reset', payload: initialState } )
        dispatch({ type: 'setDetails', details: props.jobDetails })

        if (props.modalAction === 'edit') {
            dispatch({ type: 'setFormType', formType: 'edit' })
        } else { 
            dispatch({ type: 'setFormType', formType: 'new' })
        }
    }, [props.jobDetails, props.modalAction])

    const deleteJob = async () => {
        await fetchWrapper.post('api/jobs/delete/', { 'jobNumber': state.details.number })
            .then(data => {
                if (data == 'OK') { props.handleModalClose('deleteJob', state.details)}
            })
    }

    const initialFormValues = {
        formType: state.formType,
        initialNumber: state.details.number,
        clientName: state.details.client_id,
        jobNumber: state.details.number,
        jobName: state.details.job_name,
        client_invoice_start_number: state.details.client_invoice_start_number,
    }

    const validationSchema = (
        Yup.object().shape({
            clientName: Yup.string().required(),
            jobNumber: Yup.string()
                          .required('Job Number cannot be blank')
                          .matches(/^MNY-\d+-\d+$/, { message: "Job Number must be in the format MNY-00-00", 
                                                      excludeEmptyString: true })
                          .when('formType', {
                                is: 'new',
                                then: Yup.string().test('jobNumber', 'This job number already exists',
                                    async(value) => (
                                        // Yup expects false if test is not passed and true if it is.
                                        // The API returns true if the job name already exists and false if it doesn't.
                                        // In order to reconcile these we return the opposite of the API result.

                                        fetchWrapper.post('api/jobs/check-duplicate/', { 'jobNumber': value })
                                            .then(data => !data["job exists"])
                                    ))
                          }),
            jobName: Yup.string().required('Job Name cannot be blank'),
            client_invoice_start_number: Yup.number().typeError("Invoice numbers must be numbers")
                                            .required("Cannot be blank")
                                            .integer("Invoice numbers must be whole")
                                            .moreThan(0, "Start number must be greater than zero")
        })
    )

    return (
        <>
                <Modal
                    size='lg'
                    centered
                    show={props.showJobModal}
                    onHide={() => props.handleModalClose('closeJob')}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {{
                                'edit': "Edit job: " + state.details.number,
                                'new': "Add new job",
                                'display': state.details.number
                            }[props.modalAction]}
                        </Modal.Title>
                    </Modal.Header>

                <Formik
                    enableReinitialize={true}
                    initialValues={initialFormValues}
                    validationSchema={validationSchema}
                    onSubmit={ async (values) => {
                        if (state.formType === 'new') {
                            await fetchWrapper.post('api/jobs/add/', values)
                                    .then(data => props.handleModalClose("saveJob", data))
                        } else if (state.formType === 'edit') {
                            await fetchWrapper.post('api/jobs/edit/', values)
                                    .then(data => {
                                        props.handleModalClose("saveJob",
                                            {
                                                "job": {...data.job, "initialNumber": values.initialNumber},
                                                "invoices": data.invoices ? {...data.invoices} : {}
                                            })
                                    })
                        }
                    }}
                >
                    { (form) => 
                        <>
                        <Modal.Body>
                            <Form noValidate onSubmit={form.handleSubmit}>
                                <Field name="clientName">
                                    {({field}) => (
                                        <Form.Group controlId="clientName" className="mb-3">
                                            <Form.Label>Client Name</Form.Label>
                                            {/* TODO: establish whether it needs to be possible to move jobs between Clients */}
                                            <Form.Control 
                                                value={field.value}
                                                {...field}
                                                readOnly
                                            />
                                        </Form.Group>
                                    )}
                                </Field>
                                <Field name="jobNumber">
                                    {({field, meta}) => (
                                        <Form.Group controlId="jobNumber" className="mb-3">
                                            <Form.Label>Job Number</Form.Label>
                                            <Form.Control 
                                                placeholder="MNY-00-00"
                                                value={field.value}
                                                {...field}
                                                isInvalid={meta.touched && meta.error}
                                            />
                                            <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
                                        </Form.Group>
                                    )}
                                </Field>
                                <Field name="jobName">
                                    {({field, meta}) => (
                                        <Form.Group controlId="jobName" className="mb-3">
                                            <Form.Label>Job Name</Form.Label>
                                            <Form.Control
                                                placeholder="New Job Name"
                                                value={field.value}
                                                {...field}
                                                isInvalid={meta.touched && meta.error}
                                            />
                                            <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
                                        </Form.Group>
                                    )}
                                </Field>
                                <Field name="client_invoice_start_number">
                                    {({field, meta}) => (
                                        <Form.Group controlId='client_invoice_start_number' className='mb3'>
                                            <Form.Label>Client Invoice Start Number</Form.Label>
                                            <Form.Control 
                                                placeholder='1'
                                                value={field.value}
                                                {...field}
                                                isInvalid={meta.touched && meta.error}
                                            />
                                            <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
                                        </Form.Group>
                                    )}
                                </Field>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            {props.modalAction !== 'new' && 
                                <Button id="delete" variant="danger" onClick={deleteJob}>
                                    <FontAwesomeIcon icon={faTrashAlt}/>
                                </Button>
                            }
                            <Button id="close" variant="secondary" onClick={() => props.handleModalClose('closeJob')}>Close</Button>
                            <Button id="save" variant="primary" onClick={form.handleSubmit}>Save</Button>
                        </Modal.Footer>
                        </>
                        }
                    </Formik>
                </Modal>
        </>
    )
}

export default JobModal;