import React, { useEffect, useReducer, useState } from "react";
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { Row, Col, Button } from "react-bootstrap";
import styles from './ViewInvoice.module.css';
import ViewInvoiceItem from "./ViewInvoiceItem";
import ViewInvoiceScheduleItem from "./ViewInvoiceScheduleItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faFileDownload, faPen, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import jsPDF from "jspdf";
import { useNavigate } from "react-router-dom";
import { withCookies } from "react-cookie";
import { useFetchWrapper } from "./Hooks/Fetch";

const Brand = () => {
    return (
        <h1 className="pb-3"><span className={styles.moss}>Moss</span> Naylor Young Limited</h1>
    )
}

const Address = () => {
    return (
        <small><span className={styles.moss}>Moss</span> Naylor Young Limited &middot;
                Registered Address: Brook Lane House, Rossett Business Village, Rossett, Wrexham, LL12 0AY &middot; 
                Registered at Companies House 7520263 &middot;
                VAT number 394 9065 52 </small>
    )
}

const Stamp = props => {
    const [stamp, setStamp] = useState(false)
    const [stampType, setStampType] = useState(null)
    const [stampText, setStampText] = useState("")
    const { withdrawn, paid, due_date } = props.invoice

    useEffect(() => {
        const overdue = moment(due_date) < moment()
        if ( withdrawn || paid || overdue) {
            setStamp(true)
            if (withdrawn) {
                setStampText('withdrawn')
            } else if (paid) {
                setStampText('paid')
                // setStampType(styles.stampPaid)
            } else if (overdue) {
                setStampType(styles.stampOverdue)
                setStampText('overdue')
            }
        }
    }, [withdrawn, paid, stamp, stampType, stampText])

    return (
        stamp &&
            <div className={`${styles.stamp} ${stampType}`}>{stampText}</div>
    )
}

const ClientAddress = props => {
    const addressList = props.address.split("\n")
    
    return (
        <>
            { addressList.reduce((addressBlock, item) => addressBlock === null ? item : <> { addressBlock } <br/> { item }</>, null) 
            }
        </>
    )
}

const initialState = {
    schedule: [],
    invoice: {},
    client: {},
    entries: {},
    clientInvNum: -1
}

function reducer(state, action) {
    switch(action.type) {
        case('load_entries'):
            return {
                   ...state,
                    entries: action.entries
                }
        case('load_invoice'):
            const inv = action.invoices.filter( i => i.number === Number(action.number) )[0]
            return {
                ...state,
                invoice: inv
            }
        case('load_client'):
            return {
                ...state,
                client: action.clients.filter( c => c.name === state.invoice.client_id)[0]
            }
        case('set_schedule'):
            return {
                ...state,
                schedule: action.invoices
            }
        case('set_clientInvNum'):
            return {
                ...state,
                clientInvNum: action.num + parseInt(state.invoice.client_invoice_start_number) - 1
            }
        default:
            return ( state )
    }
}

const Invoice = props => {
    const { num } = useParams();
    const [ state, dispatch ] = useReducer(reducer, initialState);
    let navigate = useNavigate()
    const fetchWrapper = useFetchWrapper()

    useEffect(() => {
        const fetchEntries = async () => {
            await fetchWrapper.post('/api/invoices/entries/', { 'invoice_number': num })
                              .then(data => dispatch({ type: 'load_entries', entries: data }))
                              .catch(error => {
                                      props.cookies.remove('user')
                                      console.log(error)
                                  })
        }

        dispatch({ type: 'load_invoice', invoices: props.invoices, number: num })

        fetchEntries().catch(console.error);
    }, [num, props.invoices])

    useEffect(() => {
        const fetchScheduleItems = async () => {
            await fetchWrapper.post('/api/invoices/list/', { 'job_number': state.invoice.job_id })
                        .then(data => {
                            dispatch({ type: 'set_schedule', invoices: data['invoices'].sort((a,b) => a.number > b.number ? 1 : -1 ) })
                            dispatch({ type: 'set_clientInvNum', num: data['invoices'].length })
                        })
                        .catch(error => { if (error === 'Denied') { props.cookies.remove('user') } })
        }

        if (state.invoice) {
            dispatch({ type: 'load_client', clients: props.clients })
            fetchScheduleItems().catch(console.error);
        }
    }, [state.invoice, props.clients, state.invoice.schedule])

    const generatePDF = e => {
        e.preventDefault()
        let doc = new jsPDF();
        let htmlString = "";

        let pages = document.getElementsByClassName('page')
        
        for ( let i = 0; i < pages.length; i++) {
            htmlString += "<div style='height: 271mm; position: relative;'>"
            htmlString += pages[i].innerHTML
            htmlString += "</div>"
        }
        
        doc.addFont('Courier')
        
        doc.html(htmlString, {
            callback: (doc) => {
                doc.save('invoice-'+num+'.pdf')
            },
            margin: [12.5, 25, 12.5, 25],
            width: 160,
            windowWidth: 604,
        })

    }

    const deleteInvoice = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ number: num })
        }

        fetch('/api/invoices/delete/', requestOptions)
                    .then(res => { if (res.ok) {
                        props.updateInvoicesList({ number: num, deleted: true })
                        navigate('/')
                    }})

    }

    return (
        <>
            <div className="d-flex justify-content-end my-3 container-fluid px-0">
                <Button className={`mx-1 ${ state.invoice && state.invoice.withdrawn ? "d-none" : "" }`}
                    onClick={ () => {
                        navigate(`/edit/${num}`)
                    }}
                >
                    <FontAwesomeIcon icon={faPen} /> Edit
                </Button>
                <Button className="mx-1"
                    onClick={ () => {
                        navigate('/new-invoice/', { search: { duplicate: `?${num}`} } )
                    }}
                >
                    <FontAwesomeIcon icon={faCopy} /> Duplicate
                </Button>
                <Button className="mx-1"
                    onClick={generatePDF}
                >
                    <FontAwesomeIcon icon={faFileDownload} /> Save
                </Button>
            </div>
            <div id="invoice">
                <div className={styles.a4}>
                    <div className={`${styles.printable} page`}>
                        <header>
                            < Brand />

                            <div className={styles.mnyAddr}>
                                <p><strong>Remittance Address</strong></p>
                                <address>
                                    Moss Naylor Young Limited<br />
                                    13 Cheap Street<br />
                                    Frome<br />
                                    BA11 1BN <br />
                                </address>
                            </div>
                            { state.invoice && <Stamp invoice={state.invoice} />}

                            <div className="text-end">
                                { state.invoice ? moment(state.invoice.issue_date).format('DD MMMM YYYY') : "" }
                            </div>
                        </header>
                        
                        <div>
                            <address>
                                { state.client ? state.client.name : "" }<br />
                                { state.client && state.client.contact !== "" ? state.client.contact : "" }<br />
                                { state.client && state.client.address && state.client.address !== "" ? <ClientAddress address={state.client.address} /> : "" }
                            </address>
                        </div>

                        <p className="pt-3">Our Ref: { state.invoice ? state.invoice.job_id : "[REF]"}/{num} </p>
                        
                        <h3 className="text-center">{ state.invoice ? state.invoice.job_name : "[Job Name]" } Invoice { state.invoice && state.invoice.inc_client_inv_num ? state.clientInvNum : ''} </h3>

                        { state.entries.time_entries && state.entries.time_entries.length > 0 ? (
                            <>
                                <Row>
                                    <h5>Time</h5>
                                </Row>
                                { state.entries.time_entries.map(entry => (
                                    <ViewInvoiceItem
                                        key={entry.id}
                                        item={entry}
                                    />
                                )) }
                                
                            </>
                        ) : "" }
                        
                        { state.entries.expense_entries && (state.entries.expense_entries).length > 0 ? (
                                <>
                                    <Row className="mt-3 pt-3">
                                        <h5>Dispersements</h5>
                                    </Row>
                                    
                                    { state.entries.expense_entries.map(entry => (
                                        <ViewInvoiceItem 
                                            item={entry}
                                        />
                                    )) }
                                </>
                        ) : "" }

                        <Row className="mt-3 pt-3">
                            <Col>
                                { state.invoice && state.invoice.vat !== null ? (
                                    <Row>
                                        <Col>VAT</Col>
                                        <Col xs={3} className="d-flex justify-content-between">
                                            <span>£</span>
                                            <span>{ parseFloat(state.invoice.vat).toFixed(2) }</span>
                                        </Col>
                                    </Row>
                                ) : "" }
                                <Row className="fw-bold">
                                    <Col>Total payable</Col>
                                    <Col xs={3} className="d-flex justify-content-between">
                                        <span>£</span>
                                        <span>{ state.invoice ? parseFloat(state.invoice.total).toFixed(2) : "" }</span>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <footer className={styles.terms}>
                            <p>PAYMENT TERMS:</p>
                            <ul>
                                <li>{state.client ? state.client.payment_terms : "[TERMS]" } days from date of invoice.</li>
                                <li>Payment to be received by Moss Naylor Young no later than <strong>{ state.invoice ? moment(state.invoice.due_date).format('DD MMMM YYYY') : "[date]" }</strong>.</li>
                            </ul>
                            <p>In the event of queries ring Patrick Moss on <a href="tel:07736859882">07736 859882</a> or email <a href="mailto:sara@mossnayloryoung.com">sara@mossnayloryoung.com</a></p>
                            <p>Payment by BACS preferred</p>
                            <Row>
                                <Col>Sort Code</Col>
                                <Col>60-02-05</Col>
                            </Row>
                            <Row>
                                <Col>Account Number</Col>
                                <Col>59327987</Col>
                            </Row>
                            <Row>
                                <Col>Account Name</Col>
                                <Col>Moss Naylor Young Limited</Col>
                            </Row>
                            <p className="mt-2">Cheques Payable to Moss Naylor Young Limited</p>

                            <Address />
                        </footer>
                    </div>
                </div>
                
                { state.invoice && (state.invoice.schedule || state.invoice.work_schedule) ? (
                    <div id="page2" className={styles.a4}>
                        <div className={`${styles.printable} page`}>
                            <header>
                                <Brand />
                            </header>
                            {
                                state.invoice.work_schedule &&
                                <>
                                    <h5>Work Schedule</h5>
                                    <Row className="mb-5">
                                    {
                                        state.invoice.work_schedule_text.split("\n").map(line => {
                                                // the search pattern covers all the common bullet points as well as - 
                                                // and * followed by a space which have been used as bullet points.
                                                const pattern = /[\u2022,\u2023,\u25E6,\u2043,\u2219,\-,*]\s/
                                                
                                                // remove blank lines used for deliniation in text boxes.
                                                if (line.length !== 0 ) {
                                                    return line.search(pattern) == 0 ? <li>{line.slice(2)}</li> : <p>{line}</p>
                                                }
                                            }
                                        )
                                    }
                                    </Row>
                                </>
                            }

                            {/* TODO: this could be a component on its own as some of the markup is duplicated in the create invoice view */}
                            {
                                state.invoice.schedule &&
                                <>
                                    <h5>Invoice Schedule</h5>
                                    <Row>
                                        <Col className="col-3"><p>Date</p></Col>
                                        <Col><p>No.</p></Col>
                                        <Col className="col-2"><p className="text-center">Amount</p></Col>
                                        <Col className="col-2"><p className="text-center">Payment</p></Col>
                                    </Row>
                                    { state.schedule.map( s => {
                                        return s.number <= parseInt(num) && (!s.withdrawn || s.number === parseInt(num)) ?
                                            <ViewInvoiceScheduleItem 
                                                key={ s.number }
                                                item={s}
                                                job_number={ state.invoice.job_id }
                                                current_invoice={s.number === parseInt(num) }
                                                view="pdf"
                                            />
                                        : ""
                                    })}
                                </>
                            }

                            <footer className="small">
                                <Address />
                            </footer>
                        </div>
                    </div>
                ) : "" }
            </div>
            <div className="my-3 container-fluid px-0">
                <Button 
                    variant="outline-danger"
                    onClick={() => {
                        if (window.confirm('Are you sure you want to delete this?')) deleteInvoice()
                    }}
                >
                    <FontAwesomeIcon icon={faTrashAlt} />
                </Button>
            </div>
        </>
    )
}

export default withCookies(Invoice);