import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import moment from "moment";

function ViewInvoiceScheduleItem(props) {
    const { issue_date, due_date, number, total, paid } = props.item
    const [ status, setStatus ] = useState("")

    useEffect(() => {
        if ( props.current_invoice && !paid ) {
            setStatus("Current")
        } else if ( paid ) {
            setStatus("Paid")
        } else if ( moment(due_date) < moment() && !paid ) {
            setStatus("Overdue")
        } else {
            setStatus("Outstanding")
        }

    }, [props.current_invoice, number, paid, due_date])

    return (
        <Row className={status === "Overdue" ? 'text-danger' : '' }>
            <div className={ props.view === 'pdf' ? "col-3" : "col-lg-1" }><p>{ moment(issue_date).format("DD/MM/YYYY") }</p></div>
            <div className="col"><p>{ props.job_number }/{ number }</p></div>
            <div className={`${props.view === 'pdf' ? "col-2" : "col-lg-1"} d-flex justify-content-between`}><span>£</span><span>{ parseFloat(total).toFixed(2) }</span></div>
            <div className={ props.view === 'pdf' ? "col-2" : "col-lg-1" }><p className="text-center small">{status}</p></div>
        </Row>
    )
}

export default ViewInvoiceScheduleItem;