import React from "react"
import { Button, FormCheck, Col, Row, Form } from "react-bootstrap"
import moment from "moment"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPen, faCopy } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from 'react-router-dom';

import styles from './ListInvoices.module.css';

function InvoiceListItem(props) {
    const { number, job_id, job_name, client_id, total, due_date, sent, paid, withdrawn } = props.invoice;
    let navigate = useNavigate()

    return (
        <Row key={number} className={`mb-5 mb-lg-1 py-1 ${styles.hover} align-items-center ${ moment(due_date) < moment() && !paid && !withdrawn && sent ? styles.overdue : ""} ${ withdrawn ? "text-decoration-line-through text-muted" : "" }`}>

            {/* INVOICE NUMBER */}
            <Col xs={1} md={{span: 'auto'}} xl={{span: 1}} className="align-self-start align-self-xxl-center">
                <p>{number}</p>
            </Col>

            {/* JOB ID: JOB NAME */}
            <Col xs={7} sm={5} md={{order: 0, span: 7}} xxl={{span: 4}}>
                <Row>
                    <Col xs={12} xxl={6}>
                        <p>{job_id}: <span className="text-nowrap">{job_name}</span></p>
                    </Col>
                    <Col xs={12} xxl={6}>
                        <p>{client_id}</p>
                    </Col>
                </Row>
            </Col>

            {/* AMOUNT DUE */}
            <Col xs={{offset: 8, order: 2, span: 4}}
                 sm={{offset: 0, span: 3}}
                 md={{offset: 0, order: 0, span: 2}}
                 xxl={1}
                 className='d-flex justify-content-between fw-bold align-self-start align-self-xxl-center'
                >
                <span>£</span>
                <span>{parseFloat(total).toFixed(2)}</span>
            </Col>

            {/* DUE DATE */}
            <Col xs={4} sm={3} md={{span: 2, order: 0}} xxl={{span: 1}} className="align-self-start align-self-xxl-center">
                <p className="text-center">{moment(due_date).format('DD/MM/YYYY')}</p>
            </Col>

            {/* force column wrap on medium/large screen sizes */}
            <div className="w-100 d-none d-md-block d-xl-none"></div>
            
            {/* CHECK BOX: ISSUED */}
            <Col xs={{span: 6, order: 2}} sm={{span: 4}} md={{span: 2, order: 0}} xxl={{span: 1}} className="d-flex justify-content-xxl-center">
                <Form.Group controlId={`issued-${number}`}>
                    <Form.Check 
                        style={{'display': 'inline'}} 
                        type="checkbox"
                        id={`issued-${number}`}
                        checked={sent}
                        disabled={withdrawn || paid}
                        onChange={e => { 
                            // TODO: make this into a pretty modal
                            const message = sent ? "Are you sure you wish to mark this invoice as not issued?" : "Has this invoice been sent to the client?";
                            
                            if (window.confirm(message)) props.updateStatus('issued', number, !sent)
                        }
                    }
                    />
                    <Form.Label className="d-xxl-none ms-2">Issued? </Form.Label>
                </Form.Group>
            </Col>

            {/* CHECK BOX: PAID */}
            <Col xs={{span: 6, order: 2}} sm={{span: 4}} md={{span: 2, order: 0}} xxl={{span: 1}} className='d-flex justify-content-xxl-center'>
                <Form.Group controlId={`issued-${number}`}>
                    <Form.Check 
                        style={{'display': 'inline'}} 
                        type="checkbox"
                        id={`paid-${number}`}
                        checked={paid}
                        disabled={!sent || withdrawn}
                        onChange={e => {
                            // TODO: make this into a pretty modal
                            const message = paid ? "Are you sure you wish to mark this invoice as unpaid?" : "Has the client paid this invoice?"
                            
                            if (window.confirm(message)) props.updateStatus('paid', number, !paid)
                        }
                    }
                    />
                    <Form.Label className="d-xxl-none ms-2">Paid?</Form.Label>
                </Form.Group>
            </Col>

            {/* CHECK BOX: WITHDRAWN */}
            <Col xs={{span: 6, order: 2}} sm={{span: 4}} md={{span: 3, order: 0}} xl={2} xxl={{span: 1}} className='d-flex justify-content-xxl-center'>
                <Form.Group controlId={`withdrawn-${number}`}>
                    <Form.Check 
                        style={{'display': 'inline'}}
                        type="checkbox"
                        checked={withdrawn}
                        disabled={paid}
                        onChange={e => {
                            const message = withdrawn ?  "This invoice was withdrawn, do you wish to reinstate it?" : "Are you sure you wish to withdraw this invoice?"
                            
                            if (window.confirm(message)) props.updateStatus('withdrawn', number, !withdrawn)
                        }}
                    />
                    <Form.Label className="d-xxl-none ms-2">Withdrawn?</Form.Label>
                </Form.Group>
            </Col>

            {/* EDIT, DUPLICATE AND VIEW BUTTONS */}
            <Col xs={{span: 12, order: 2}} md={{span: 5, order: 0}} xl={6} xxl={{span: 2}}>
                <Row className="justify-content-evenly">

                    {/* EDIT */}
                    <Col className="d-flex justify-content-center">
                        <Button
                            disabled={withdrawn}
                            name={`edit-${number}`}
                            onClick={ () => {
                                navigate(`/edit/${number}`)
                            }}
                        >
                            <FontAwesomeIcon icon={faPen}/>
                        </Button>
                    </Col>

                    {/* DUPLICATE */}
                    <Col className="d-flex justify-content-center">
                            <Button
                                name={`duplicate-${number}`}
                                onClick={ () => {
                                    props.setDuplicate(number)
                                    navigate('/new-invoice/')
                                }}
                            >
                                <FontAwesomeIcon icon={faCopy}/>
                            </Button>
                    </Col>

                    {/* VIEW */}
                    <Col className="d-flex justify-content-center">
                        <Button
                            name={`view-${number}`}
                            onClick={ () => {
                                navigate(`/invoice/${number}`)
                            }}
                        >
                            <FontAwesomeIcon icon={faEye} />
                        </Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default InvoiceListItem;