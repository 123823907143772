import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import { Col, Row, Form, Table, Button, Container } from 'react-bootstrap';
import ClientModal from './ClientModal';
import PageHeader from './Navigation/PageHeader';
import JobModal from './JobModal';

import styles from './ListInvoices.module.css';
import { useFetchWrapper } from './Hooks/Fetch';

const ListClients = props => {
    const [ showClientModal, setShowClientModal ] = useState(false);
    const [ showJobModal, setShowJobModal ] = useState(false);
    const [ modalAction, setModalAction ] = useState("");
    const [ prevAction, setPrevAction ] = useState("");
    const [ clientDetails, setClientDetails ] = useState({});
    const [ associatedJobs, setAssociatedJobs ] = useState([]);
    const [ jobDetails, setJobDetails ] = useState({})
    const fetchWrapper = useFetchWrapper();

    useEffect(() => {
        if (clientDetails && clientDetails !== {}) {
            setAssociatedJobs(props.jobs.filter(
                job => job.client_id === clientDetails.name
            ))
        } else {
            setAssociatedJobs({})
        }
    }, [clientDetails, props.jobs])

    const handleModalShow = (action, client) => {
        if (client) {
            setClientDetails(props.clients.filter(c => c.name === client)[0])
        }
        setModalAction(action)
        setShowClientModal(true)
    }

    const deleteClient = async () => {
        // TODO: make this pretty
        if (window.confirm("Are you sure you wish to delete this client, their associated jobs and invoices? THIS ACTION CANNOT BE UNDONE")) {
            await fetchWrapper.post('/api/clients/delete/', { 'client_id': clientDetails.id })
                .then(data => {
                    if (data == 'OK') { handleModalClose('deleteClient', { "name": clientDetails.name } )}
                })
        }
    }

    const handleModalClose = (e, v) => {
        let close = ""
        
        switch (e) {
            case 'saveClient':
                props.updateClientsList(v)
                setPrevAction('details')
                close = 'client'
                break
            case 'closeClient':
                close = 'client'
                break;
            case 'deleteClient':
                props.updateClientsList({...v, 'delete': true})
                close = 'client'
                break
            case 'closeJob':
                close = 'job'
                break
            case 'saveJob':
                console.log(v)
                props.updateJobsList({...v.job})
                if (v.invoices) { props.updateInvoiceList({...v.invoices})}
                close = 'job'
                break
            case 'deleteJob':
                props.updateJobsList({...v, 'deleted': true})
                close = 'job'
                break
        }
                
        if (close === 'client') {
            if (prevAction === 'details' && e !== "closeClient") {
                console.log("prevAction === details")
                setClientDetails(v)
                setPrevAction("")
                setModalAction('details')
            } else {
                setShowClientModal(false)
                setClientDetails({})
                setModalAction('')
            }
        } else {
            prevAction !== modalAction ? setModalAction(prevAction) : setModalAction('details')
            setPrevAction("")
        
            setJobDetails({})
            setShowJobModal(false)
    
            setShowClientModal(true)
        }

    }

    const handleModalChange = (e, v) => {
        setPrevAction(modalAction)
        
        switch (e) {
            case 'editJob':
                setJobDetails(props.jobs.filter(job => job.number === v)[0])
                setModalAction('edit')
                break
            case 'editClient':
                setModalAction('edit')
                break
            case 'newJob':
                setJobDetails({'client_id': clientDetails.name})
                setModalAction('new')            
                break
            case 'firstJob':
                props.updateClientsList(v)
                setClientDetails(v)
                setJobDetails({ 'client_id': v.name})
                break
        }
        
        if (e.includes('Job')) {
            setShowClientModal(false)
            setShowJobModal(true)
        }
    }

    return (
        <React.Fragment>
            <PageHeader
                loc="/clients"
                handleModalShow={handleModalShow}
            />
            <div>
                <Form className="row">
                    {/* TODO: add functionality */}
                    {/* TODO: clear filters */}
                    <Col>
                        <Form.Group as={Row} controlId='clientSearch'>
                            <Form.Label column xs='auto'>Search</Form.Label>
                            <Col>
                                <Form.Control 
                                    placeholder='Client Name'
                                />
                            </Col>
                        </Form.Group>
                    </Col>
                    <Col xs='auto'>
                        <Button type="submit">Search</Button>
                    </Col>
                </Form>
            </div>
            <Container fluid className='mx-0 px-0 pt-3'>
                    { props.clients.map( client => (
                        <Row key={client.name} className={`py-2 ${styles.hover}`}>
                            <Col xxl={10}>
                                <Button variant='link' onClick={ () => handleModalShow('details', client.name)}>{client.name}</Button>
                            </Col>
                            <Col xxl={1} className='text-center'>
                                <Button onClick={ () => handleModalShow('edit', client.name)}><FontAwesomeIcon icon={faPencilAlt} /></Button>
                            </Col>
                            <Col xxl={1} className='text-center'>
                                <Button onClick={ () => handleModalShow('details', client.name)}><FontAwesomeIcon icon={faEye} /></Button>
                            </Col>
                        </Row>
                    ))}
            </Container>
            
            <ClientModal
                showClientModal={showClientModal}
                handleModalClose={handleModalClose}
                handleModalChange={handleModalChange}
                modalAction={modalAction}
                clientDetails={clientDetails}
                associatedJobs={associatedJobs}
                deleteClient={deleteClient}
            />
            <JobModal 
                showJobModal={showJobModal}
                handleModalClose={handleModalClose}
                modalAction={modalAction}
                jobDetails={jobDetails}
            />
        </React.Fragment>
    )
}

export default ListClients;